const protocol = document.URL.split("://")[0] + "://";
const knowledgebase = document.querySelectorAll("#knowledgebase");

if (knowledgebase.length > 0) {
  const pageUrl = knowledgebase[knowledgebase.length - 1].getAttribute(
    "data-toggle-pageurl"
  );
  const knowledgebaseDomain =
    protocol +
    knowledgebase[knowledgebase.length - 1].getAttribute(
      "data-toggle-knowledgebasedomain"
    );
  const searchUrl = protocol + extractHostname(document.URL) + pageUrl;
  const fetchUrl = knowledgebaseDomain + "/search?referredurl=" + searchUrl + "&t=referred";
 
  fetch(fetchUrl)
    .then((res) => res.json())
    .then((json) => {
      if (json.items !== null && json.items !== undefined) {
        for (let i = 0; i < json.items.length; i++) {
          let childNode = '<ul class="kennisbank_toggle"><li class="kennisbanktog"><a href="' + knowledgebaseDomain + json.items[i].url + '"><img class="icon" src="/libs/kennisbank-toggle/dist/images/book.svg">Kennisbank<img class="icon" src="/libs/kennisbank-toggle/dist/images/arr-forward.svg"></a></li></ul>';
          knowledgebase[knowledgebase.length - 1].insertAdjacentHTML('beforeend', childNode)
        }
      }
    })
    .catch((err) => {
      throw err;
    });
}

window.onscroll = function () {
  toggleScroll();
};

function toggleScroll() {
  let scrollButton = document.querySelectorAll('.kennisbank_toggle');
  if (document.documentElement.scrollTop > 200) {
    for (var i = 0, all = scrollButton.length; i < all; i++) {
      scrollButton[i].classList.add("fixed");
    }
  } else {
    for (var i = 0, all = scrollButton.length; i < all; i++) {
      scrollButton[i].classList.remove("fixed");
    }
  }
}

function extractHostname(url) {
  let hostname;
  //find & remove protocol (http, ftp, etc.) and get the hostname
  if (url.indexOf("://") > -1) {
    hostname = url.split("/")[2];
  } else {
    hostname = url.split("/")[0];
  }
  //find & remove port number
  return hostname.replace("www.", "");
}
